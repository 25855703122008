<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="类型">
                <a-select v-model="queryParam.appType" placeholder="请选择" allow-clear>
                  <a-select-option v-for="(item,key) in typeOptions" :key="key" :value="item.value">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="版本号名称">
                <a-input v-model="queryParam.versionName" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="版本号">
                <a-input v-model="queryParam.versionCode" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()">
          <a-icon type="plus" />新增
        </a-button>
        <!-- <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['valve:project:edit']">
          <a-icon type="edit" />修改
        </a-button> -->
        <a-button type="danger" :disabled="multiple" @click="handleDelete">
          <a-icon type="delete" />删除
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 升级列表展示 -->
      <update-list
        v-if="updateListVisible"
        ref="updateList"

      ></update-list>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <!-- <span slot="appType" slot-scope="text, record">
          <span v-if="record.appType == 0">安卓</span>
          <span v-else-if="record.appType == 1">IOS</span>
          <span v-else-if="record.appType == 2">固件</span>
        </span> -->
        <span slot="appType" slot-scope="text, record">
          {{ record.appType|filterType(typeOptions) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="status" slot-scope="text, record">
          {{ record.status|filterStatus }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a v-hasPermi="['biz:app/version:status']" v-if="record.status==0||record.status==2" @click="handleConfirmUpdate(record, 1)">升级</a>
          <a v-hasPermi="['biz:app/version:status']" v-else @click="handleConfirmUpdate(record, 2)">暂停</a>
          <a-divider type="vertical" v-hasPermi="['biz:app/version:status']" />
          <a v-hasPermi="['biz:versioninfoassign:list']" @click="handleShowDev(record)">升级设备列表</a>
          <a-divider type="vertical" v-hasPermi="['biz:versioninfoassign:list']" />
          <!-- <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['valve:project:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['valve:project:remove']" /> -->
          <a v-if="record.status !== 1" @click="handleDelete(record)">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageappVersion, delappVersion, confirmUpdate } from '@/api/system/version'
import CreateForm from './modules/CreateForm'
import UpdateList from './modules/updateList.vue'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Project',
  components: {
    CreateForm,
    UpdateList
  },
  mixins: [tableMixin],
  data () {
    return {
      updateListVisible: false,
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        appType: undefined,
        versionName: null,
        versionCode: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '类型',
          dataIndex: 'appType',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'appType' }
        },
        {
          title: '版本号名称',
          dataIndex: 'versionName',
          ellipsis: true,
          width: 100,
          align: 'center'
        },
        {
          title: '版本号',
          dataIndex: 'versionCode',
          ellipsis: true,
          width: 80,
          align: 'center'
        },
        {
          title: '更新说明',
          dataIndex: 'upNotes',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '下载地址',
          dataIndex: 'downloadUrl',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '升级状态',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '添加时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',
          width: 160
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 250,
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      typeOptions: [
        { value: 0, name: '电信4G有磁有阀' },
        { value: 1, name: '电信4G有磁无阀' },
        { value: 2, name: '电信4G无磁有阀' },
        { value: 3, name: '电信4G无磁无阀' },
        { value: 4, name: '平台4G有磁有阀' },
        { value: 5, name: '平台4G有磁无阀' },
        { value: 6, name: '平台4G无磁有阀' },
        { value: 7, name: '平台4G无磁无阀' },
        { value: 8, name: '电信NB有磁有阀' },
        { value: 9, name: '电信NB有磁无阀' },
        { value: 10, name: '电信NB无磁有阀' },
        { value: 11, name: '电信NB无磁无阀' },
        { value: 12, name: '自己平台NB有磁有阀' },
        { value: 13, name: '自己平台NB有磁无阀' },
        { value: 14, name: '自己平台NB无磁有阀' },
        { value: 15, name: '自己平台NB无磁无阀' }
      ]
    }
  },
  filters: {
    filterStatus(value) {
      const arr = [
        { value: 0, name: '等待升级' },
        { value: 1, name: '升级中' },
        { value: 2, name: '暂停升级' },
        { value: 3, name: '升级完成' }
      ]
      return value !== undefined ? arr.filter(item => item.value === value)[0].name : ''
    },
    filterType(value, arr) {
      return value !== undefined ? arr.filter(item => item.value === value)[0].name : ''
    }
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    handleShowDev(record) {
      this.updateListVisible = true
      this.$nextTick(() => {
        this.$refs.updateList.init(record)
      })
    },
    handleConfirmUpdate(record, status) {
      this.$confirm({
        title: '提示',
        content: '确定操作此数据吗？',
        onOk: () => {
          return new Promise((resolve, reject) => {
            confirmUpdate({ id: record.id, status }).then(res => {
              this.getList()
              resolve(res)
            }).catch(() => {
              reject(new Error('失败'))
            })
          }).catch(() => console.log('Oops errors!'))
        },
        cancelText: '取消'
      })
    },
    /** 查询项目信息列表 */
    getList () {
      this.loading = true
     pageappVersion(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        appType: undefined,
        versionName: null,
        versionCode: null,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '提示',
        content: '确认删除所选中数据?',
        onOk () {
          const data = []
          that.ids.map(id => data.push({ 'id': id }))
          return delappVersion(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    }
  }
}
</script>
