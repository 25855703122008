var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-drawer',{attrs:{"title":_vm.$t(_vm.title),"width":_vm.width,"body-style":{ overflowY: 'auto', paddingBottom: '80px', ..._vm.bodyStyle },"visible":_vm.value},on:{"update:visible":function($event){_vm.value=$event},"close":_vm.onClose}},[_c('a-spin',{attrs:{"spinning":_vm.loading},on:{"update:spinning":function($event){_vm.loading=$event}}},[(_vm.$slots.title)?_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._t("title")],2):_vm._e(),_vm._t("default"),_c('div',{style:({
        position: 'fixed',
        right: 0,
        bottom: 0,
        width: _vm.width,
        borderTop: '1px solid #e9e9e9',
        padding: '10px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 1,
      })},[(_vm.cancelText)?_c('a-button',{style:({ marginRight: '8px' }),on:{"click":_vm.onClose}},[_vm._v(" "+_vm._s(_vm.$t(_vm.cancelText))+" ")]):_vm._e(),(_vm.confirmText)?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$emit('confirm')}}},[_vm._v(" "+_vm._s(_vm.$t(_vm.confirmText))+" ")]):_vm._e(),_vm._t("footer")],2)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }