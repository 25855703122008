<template>
  <cy-drawer
    width="80%"
    v-model="visible"
    @cancel="cancel"
  >
    <a-descriptions title="升级信息" bordered>
      <a-descriptions-item label="类型">
        {{ info.appType|filterType }}
      </a-descriptions-item>
      <a-descriptions-item label="版本名称">
        {{ info.versionName }}
      </a-descriptions-item>
      <a-descriptions-item label="版本号">
        {{ info.versionCode }}
      </a-descriptions-item>
      <a-descriptions-item label="更新说明">
        {{ info.upNotes }}
      </a-descriptions-item>
      <a-descriptions-item label="下载地址">
        {{ info.downloadUrl }}
      </a-descriptions-item>
    </a-descriptions>
    <!-- 条件搜索 -->
    <div class="table-page-search-wrapper" style="margin-top: 15px;">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="设备名称">
              <a-input v-model="queryParam.objName" placeholder="请输入" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="设备编号">
              <a-input v-model="queryParam.objPhone" placeholder="请输入" allow-clear/>
            </a-form-item>
          </a-col>

          <template v-if="advanced">
          </template>
          <a-col :md="!advanced && 6 || 24" :sm="24">
            <span
              class="table-page-search-submitButtons"
              :style="advanced && { float: 'right', overflow: 'hidden' } || {}">
              <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
              <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              <a @click="toggleAdvanced" style="margin-left: 8px">
                {{ advanced ? '收起' : '展开' }}
                <a-icon :type="advanced ? 'up' : 'down'" />
              </a>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 数据展示 -->
    <a-table
      :loading="loading"
      :size="tableSize"
      rowKey="id"
      :columns="columns"
      :data-source="list"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :pagination="false"
      :bordered="tableBordered"
    >
      <!-- <span slot="appType" slot-scope="text, record">
          <span v-if="record.appType == 0">安卓</span>
          <span v-else-if="record.appType == 1">IOS</span>
          <span v-else-if="record.appType == 2">固件</span>
        </span> -->
      <span slot="updateStatus" slot-scope="text, record">
        {{ parseTime(record.updateStatus) }}
      </span>
      <span slot="createTime" slot-scope="text, record">
        {{ parseTime(record.createTime) }}
      </span>
      <span slot="status" slot-scope="text, record">
        {{ record.status|filterStatus }}
      </span>
      <span slot="operation" slot-scope="text, record">
        <!-- <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['valve:project:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['valve:project:remove']" /> -->
        <a @click="handleDelete(record)">
          <a-icon type="delete" />删除
        </a>
      </span>
    </a-table>
    <!-- 分页 -->
    <a-pagination
      class="ant-table-pagination"
      show-size-changer
      show-quick-jumper
      :current="queryParam.pageNum"
      :total="total"
      :page-size="queryParam.pageSize"
      :showTotal="total => `共 ${total} 条`"
      @showSizeChange="onShowSizeChange"
      @change="changeSize"
    />
  </cy-drawer>
</template>
<script>
import CyDrawer from '@/components/CyDarwer'
import { fetchList, delObj } from '../api'
export default {
  components: { CyDrawer },
  data() {
    return {
      advanced: false,
      visible: false,
      info: {},
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        objId: undefined,
        appVersionInfoId: undefined,
        objName: undefined,
        objPhone: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: '类型',
        //   dataIndex: 'appType',
        //   ellipsis: true,
        //   width: 80,
        //   align: 'center'
        // },
        // { title: '设备、用户id标识', dataIndex: 'objId', ellipsis: true, align: 'center' },
        { title: '设备名称', dataIndex: 'objName', ellipsis: true, align: 'center' },
        { title: '设备编号', dataIndex: 'objPhone', ellipsis: true, align: 'center' },
        { title: '备注', dataIndex: 'remark', ellipsis: true, align: 'center' },
        { title: '更新状态', dataIndex: 'status', ellipsis: true, align: 'center', scopedSlots: { customRender: 'status' } },
        { title: '更新状态时间', dataIndex: 'updateStatus', ellipsis: true, align: 'center', scopedSlots: { customRender: 'updateStatus' } },
        { title: '上传时间', dataIndex: 'createTime', ellipsis: true, align: 'center', scopedSlots: { customRender: 'createTime' } },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 250,
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
    filterStatus(value) {
      const arr = [
        { value: 0, name: '等待更新' },
        { value: 1, name: '更新成功' },
        { value: 2, name: '更新失败' },
        { value: 3, name: '升级完成' }
      ]
      return value !== undefined ? arr.filter(item => item.value === value)[0].name : ''
    },
    filterType(value) {
      const arr = [
        { value: 0, name: '电信4G有磁有阀' },
        { value: 1, name: '电信4G有磁无阀' },
        { value: 2, name: '电信4G无磁有阀' },
        { value: 3, name: '电信4G无磁无阀' },
        { value: 4, name: '平台4G有磁有阀' },
        { value: 5, name: '平台4G有磁无阀' },
        { value: 6, name: '平台4G无磁有阀' },
        { value: 7, name: '平台4G无磁无阀' },
        { value: 8, name: '电信NB有磁有阀' },
        { value: 9, name: '电信NB有磁无阀' },
        { value: 10, name: '电信NB无磁有阀' },
        { value: 11, name: '电信NB无磁无阀' },
        { value: 12, name: '自己平台NB有磁有阀' },
        { value: 13, name: '自己平台NB有磁无阀' },
        { value: 14, name: '自己平台NB无磁有阀' },
        { value: 15, name: '自己平台NB无磁无阀' }
      ]
      return value !== undefined ? arr.filter(item => item.value === value)[0].name : ''
    }
  },
  methods: {
    cancel() {
      this.visible = false
      this.$emit('close')
    },
    init(record) {
      this.info = record
      this.queryParam.appVersionInfoId = record.id
      this.visible = true
      this.getList()
    },
    /** 查询项目信息列表 */
    getList () {
      this.loading = true
     fetchList(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        objId: undefined,
        appVersionInfoId: this.info.appVersionInfoId,
        objName: undefined,
        objPhone: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      this.$confirm({
        title: '提示',
        content: '确认删除所选中数据?',
        onOk () {
          return delObj({ id: row.id })
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
      // const ids = row.id || this.ids
      // if (row.id) {
      //   this.ids.push(row.id)
      // }
      // this.$confirm({
      //   title: '提示',
      //   content: '确认删除所选中数据?',
      //   onOk () {
      //     const data = []
      //     that.ids.map(id => data.push({ 'id': id }))
      //     return delObj(data)
      //       .then(() => {
      //         that.onSelectChange([], [])
      //         that.getList()
      //         that.$message.success(
      //           '删除成功',
      //           3
      //         )
      //     })
      //   },
      //   onCancel () {}
      // })
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    }
  }
}
  </script>
  <style lang="less" scoped>

  </style>
